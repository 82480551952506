import React from 'react'
import {useSelector} from 'react-redux'
import {Navigate, useLocation} from 'react-router-dom'

const RequireAuthentication = ({children}) => {

    const location = useLocation()
    const user = useSelector(state => state.user)
    if (!user.isAuthenticated) {
        return (
            <Navigate to='sign-in' state={{from: location}}/>
        )
    } else {
        return children
    }
}

export {RequireAuthentication}
