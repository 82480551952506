export const headCells = [
    {
        id: 'number',
        type: 'numeric',
        label: '№',
        width: 50,
    },
    {
        id: 'requestType',
        label: 'Тип',
        disablePadding: false
    },
    {
        id: 'terminal',
        label: 'Терминал',
    },
    {
        id: 'plannedStartDate',
        type: 'date',
        label: 'Начало',
        width: 100,
    },
    {
        id: 'plannedEndDate',
        type: 'date',
        label: 'Окончание',
        width: 100,
    },
    {
        id: 'carriagesNumber',
        label: 'Вагоны',
        width: 60,
    },
    {
        id: 'railwayTrack',
        label: 'Путь подачи',
        width: 80,
    },
    {
        id: 'cargoTypes',
        label: 'Груз',
    },
    {
        id: 'notes',
        label: 'Примечание',
    },
];