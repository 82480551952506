import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchData, itemSelected} from 'store/requestSlice'
import {Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import 'moment/locale/ru'
import moment from 'moment-timezone';
import {LoadingPopover} from "components/LoadingPopover";
import {useClasses} from "hooks/useClasses";
import {headCells} from "./template";
import ToolButtonPanel from "components/ToolButtonPanel";

const styles = (theme) => ({
    bodyContainer: {
        width: '100%',
        height: 'calc(100vh - 160px)'
    },
    tableContainer: {
        backgroundColor: theme.components.table.backgroundColor
    },
    headerCell: {
        padding: '1px 12px',
        fontSize: '13px',
        color: '#9E9E9E',
    },
    dataCell: {
        padding: '1px 12px',
        fontSize: '13px',
    },
})

function RequestTableHead() {
    const {classes} = useClasses(styles)
    return (
        <TableHead style={{height: 48}}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox disabled/>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        className={classes.headerCell}
                        width={headCell.width}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function RequestsView() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const requests = useSelector(state => state.requests)
    const [selected, setSelected] = useState(null)
    const {classes} = useClasses(styles)

    useEffect(() => {
        dispatch(fetchData({user}))
    }, [dispatch])

    const handleRowClick = (selection) => {
        const newSelection = selection.id !== requests.selectedItemId ? selection : null
        setSelected(newSelection)
        dispatch(itemSelected(newSelection)
        )
    }

    const isSelected = (id) => requests.selectedItemId === id

    return (
        <div className={classes.tableContainer}>
            {/*<ToolButtonPanel selected={selected}/>*/}

            <div className={classes.bodyContainer}>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <RequestTableHead/>
                        <TableBody>
                            {requests && requests.data.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `selection-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            key={row.id}
                                            selected={isItemSelected}
                                            onClick={() => handleRowClick(row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            sx={{cursor: 'pointer'}}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                    color={"primary"}
                                                />
                                            </TableCell>

                                            <TableCell style={{...styles.dataCell, width: 50}}>{row.number}</TableCell>
                                            <TableCell className={classes.dataCell}>{row.requestType}</TableCell>
                                            <TableCell className={classes.dataCell}>{row.terminal}</TableCell>
                                            <TableCell style={{...styles.dataCell, width: 100}}>
                                                {
                                                    row.plannedStartDate ?
                                                        moment(row.plannedStartDate).tz('Europe/Moscow').locale('ru').format('lll')
                                                        :
                                                        null
                                                }
                                            </TableCell>

                                            <TableCell style={{...styles.dataCell, width: 100}}>
                                                {
                                                    row.plannedEndDate ?
                                                        moment(row.plannedEndDate).tz('Europe/Moscow').locale('ru').format('lll')
                                                        :
                                                        null
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{...styles.dataCell, width: 60}}>{row.carriagesNumber}</TableCell>
                                            <TableCell
                                                style={{...styles.dataCell, width: 80}}>{row.railwayTrack}</TableCell>
                                            <TableCell className={classes.dataCell}>{row.cargoTypes}</TableCell>
                                            <TableCell className={classes.dataCell}>{row.notes}</TableCell>
                                        </TableRow>
                                    )
                                }
                            )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {requests.loading && <LoadingPopover/>}
            </div>
        </div>
    )
}


export default RequestsView
