import React from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import {useClasses} from "../../hooks/useClasses";

const styles = () => ({
    loadingContainer: {
        width: 100,
        height: 100,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
        zIndex: 4000
    }
})

export function LoadingPopover() {
    const {classes} = useClasses(styles)
    return (
        <div className={classes.loadingContainer}>
            <CircularProgress/>
        </div>
    )
}
