import React from 'react';
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";


const ToolButton = styled(Button)(({theme}) => ({
    color: theme.components.toolButton.color,
    backgroundColor: theme.components.toolButton.backgroundColor,
    '&:hover': {
        backgroundColor: theme.components.toolButton.backgroundHoverColor,
        color: theme.components.toolButton.hoverColor,
    },
    borderRadius: 0,
    height: 48,
    margin: '0px 12px',
}));


export const ToolButtonBase = ({showCondition, label, onClick, disabled}) => {

    if (showCondition) {
        return (
            <ToolButton
                label={label}
                variant="contained"
                disabled={disabled}
                onClick={onClick}
            >
                {label}
            </ToolButton>)
    }
    return null
};

export default ToolButtonBase;