import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import config from '../etc/config';
import {calculateStateProps} from "./utils";

const getConfig = (user) => {
    return {
        headers: {
            'Authorization': `Bearer ${user.token}`,
            'Accept': 'application/json'
        }
    }
}

export const fetchData = createAsyncThunk(
    'requests/fetchData',
    async function ({user}, {dispatch, rejectWithValue}) {
        try {
            const requestConfig = getConfig(user)
            requestConfig.params = {
                locomotiveId: user.locomotiveId
            }
            const response = await axios.get(`${config.apiBaseUrl}/v1/Requests`, requestConfig)
            return response.data
        } catch (e) {
            const errorMessage = {status: e.response.status, message: e.response.statusText}
            return rejectWithValue(errorMessage)
        }
    }
);

export const locomotiveFailure = createAsyncThunk(
    'requests/locomotiveFailure',
    async function ({user}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Locomotives/${user.locomotiveId}/Failure`, null, getConfig(user))
            dispatch({
                type: 'requests/snackbarShowed',
                payload: {snackbarMessage: 'Локомотив был переведен во внеплановый ремонт'}
            })
            setTimeout(() => dispatch({type: 'requests/snackbarHidden'}), 5000)
        } catch (e) {
            console.log(e)
            return rejectWithValue(e.message)
        }
    }
);

export const driverChanging = createAsyncThunk(
    'requests/driverChanging',
    async function ({user}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Locomotives/${user.locomotiveId}/DriverChanging`, null, getConfig(user))
            dispatch({
                type: 'requests/snackbarShowed',
                payload: {snackbarMessage: 'Смена принята'}
            })
            setTimeout(() => dispatch({
                type: 'requests/snackbarHidden'
            }), 5000)
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
);


export const arrivalUnderTrain = createAsyncThunk(
    'requests/arrivalUnderTrain',
    async function ({user, request}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Requests/${request.id}/ArrivalUnderTrain`, null, getConfig(user))
            dispatch(fetchData({user}))
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)

export const supplyStart = createAsyncThunk(
    'requests/supplyStart',
    async function ({user, request}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Requests/${request.id}/SupplyStart`, null, getConfig(user))
            dispatch(fetchData({user}))
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)

export const removeFinish = createAsyncThunk(
    'requests/removeFinish',
    async function ({user, request}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Requests/${request.id}/RemoveFinish`, null, getConfig(user))
            dispatch(fetchData({user}))
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)

export const workStart = createAsyncThunk(
    'requests/workStart',
    async function ({user, request}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Requests/${request.id}/WorkStart`, null, getConfig(user))
            dispatch(fetchData({user}))
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)

export const trainFixing = createAsyncThunk(
    'requests/trainFixing',
    async function ({user, request}, {
        rejectWithValue,
        dispatch
    }) {
        try {
            await axios.post(`${config.apiBaseUrl}/v1/Requests/${request.id}/TrainFixing`, null, getConfig(user))
            dispatch(fetchData({user}))
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)


const requestSlice = createSlice({
    name: "requests",
    initialState: {
        data: [],
        loading: false,
        selectedItemId: null,
        arrivalUnderTrainButtonShow: false,
        arrivalUnderTrainButtonEnable: false,
        trainFixingButtonShow: false,
        trainFixingButtonEnable: false,
        supplyStartButtonShow: false,
        supplyStartButtonEnable: false,
        removeFinishButtonShow: false,
        removeFinishButtonEnable: false,
        removeFinishWorkButtonShow: false,
        removeFinishWorkButtonEnable: false,
        workStartButtonShow: false,
        workStartButtonEnable: false,
        snackbarShow: false,
        snackbarMessage: '',
        error: null,
    },
    reducers: {
        clearError: (state) => {
            state.error = null
        },
        snackbarShowed: (state, action) => {
            state.snackbarShow = true
            state.snackbarMessage = action.payload.snackbarMessage
        },
        snackbarHidden: (state) => {
            state.snackbarShow = false
            state.snackbarMessage = ''
        },

        itemSelected(state, action) {
            const stateProps = calculateStateProps(action.payload)
            state.selectedItemId = action.payload?.id
            state.arrivalUnderTrainButtonShow = stateProps.arrivalUnderTrainButtonShow
            state.arrivalUnderTrainButtonEnable = stateProps.arrivalUnderTrainButtonEnable
            state.trainFixingButtonShow = stateProps.trainFixingButtonShow
            state.trainFixingButtonEnable = stateProps.trainFixingButtonEnable
            state.supplyStartButtonShow = stateProps.supplyStartButtonShow
            state.supplyStartButtonEnable = stateProps.supplyStartButtonEnable
            state.removeFinishButtonShow = stateProps.removeFinishButtonShow
            state.removeFinishButtonEnable = stateProps.removeFinishButtonEnable
            state.removeFinishWorkButtonShow = stateProps.removeFinishWorkButtonShow
            state.removeFinishWorkButtonEnable = stateProps.removeFinishWorkButtonEnable
            state.workStartButtonShow = stateProps.workStartButtonShow
            state.workStartButtonEnable = stateProps.workStartButtonEnable
        }
    },
    extraReducers: {
        [fetchData.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [fetchData.fulfilled]: (state, action) => {
            const data = action.payload
            const selected = data.find(item => item.id === state.requests?.selectedItemId)
            const stateProps = calculateStateProps(selected)

            state.data = data
            state.loading = false
            state.selectedItemId = selected?.id
            state.arrivalUnderTrainButtonShow = stateProps.arrivalUnderTrainButtonShow
            state.arrivalUnderTrainButtonEnable = stateProps.arrivalUnderTrainButtonEnable
            state.trainFixingButtonShow = stateProps.trainFixingButtonShow
            state.trainFixingButtonEnable = stateProps.trainFixingButtonEnable
            state.supplyStartButtonShow = stateProps.supplyStartButtonShow
            state.supplyStartButtonEnable = stateProps.supplyStartButtonEnable
            state.removeFinishButtonShow = stateProps.removeFinishButtonShow
            state.removeFinishButtonEnable = stateProps.removeFinishButtonEnable
            state.removeFinishWorkButtonShow = stateProps.removeFinishWorkButtonShow
            state.removeFinishWorkButtonEnable = stateProps.removeFinishWorkButtonEnable
            state.workStartButtonShow = stateProps.workStartButtonShow
            state.workStartButtonEnable = stateProps.workStartButtonEnable
        },
        [fetchData.rejected]: (state, action) => {
            state.error = action.payload
        },

        [locomotiveFailure.pending]: (state) => {
        },
        [locomotiveFailure.fulfilled]: (state, action) => {
        },
        [locomotiveFailure.rejected]: (state, action) => {
            state.error = action.payload
        },

        [driverChanging.pending]: (state) => {
        },
        [driverChanging.fulfilled]: (state, action) => {
        },
        [driverChanging.rejected]: (state, action) => {
            state.error = action.payload
        },

        [trainFixing.pending]: (state) => {
        },
        [trainFixing.fulfilled]: (state, action) => {
        },
        [trainFixing.rejected]: (state, action) => {
            console.log(action.payload)
            state.error = action.payload
        },


    }

});

export const {
    itemSelected, clearError

} = requestSlice.actions;

export default requestSlice.reducer;
