import React from 'react';
import {AppBar, Typography} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {fetchData} from "store/requestSlice";
import {signOut} from "store/userSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useClasses} from "hooks/useClasses";
import MainButton from "../MainButton";
import clarisLogo from "../../containers/SignInView/claris-logo.png";

const styles = (theme) => ({
    appBarContentWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 24,
        paddingLeft: 24,
        alignItems: 'center',
        height: 64,
        backgroundColor: theme.palette.primaryBgColor,
    },
    appBarSignWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 24,
        paddingLeft: 24,
        alignItems: 'center',
        height: 64,
        backgroundColor: theme.components.signIn.appBarBackgroundColor,
    },
    logoContainer: {
        padding: 8
    },
    logo: {
        opacity: 0.7
    },
    appBarLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: theme.palette.primaryTextColor,
    },
    appBarButtonPanel: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
});

const MainBar = ({user}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {classes} = useClasses(styles)

    const handleRefreshButtonClick = () => {
        dispatch(fetchData({user}))
    }

    const handleSignOutButtonClick = () => {
        dispatch(signOut())
        navigate('/sign-in')
    }

    return (
        <AppBar position="static">
            {user.isAuthenticated
                ?
                <div className={classes.appBarContentWrapper}>
                    <Typography variant="h5" className={classes.appBarLabel}>
                        Заявки
                    </Typography>
                    <div className={classes.appBarButtonPanel}>
                        <MainButton onClick={handleRefreshButtonClick} label={'Обновить'} startIcon={<RefreshIcon/>}/>
                        <MainButton onClick={handleSignOutButtonClick} label={'Выход'}
                                    startIcon={<PowerSettingsNewIcon/>}/>
                    </div>
                    <Typography variant="h5" className={classes.appBarLabel}>
                        {user.locomotiveName}
                    </Typography>
                </div>
                :
                <div className={classes.appBarSignWrapper}>
                    <div className={classes.logoContainer}>
                        <img className={classes.logo} src={clarisLogo} alt="Claris Logo"/>
                    </div>
                </div>
            }
        </AppBar>
    );
};

export default MainBar;