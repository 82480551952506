import React from 'react';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {useResize} from "hooks/useResize";
import {styled} from "@mui/material/styles";


const StyledButton = styled(Button)(({theme}) => ({
    color: theme.palette.primaryTextColor,
    '&:hover': {
        backgroundColor: theme.palette.primaryHvBgColor,
    },
}));

const StyledIconButton = styled(IconButton)(({theme}) => ({
    color: theme.palette.primaryTextColor,
    '&:hover': {
        backgroundColor: theme.palette.primaryHvBgColor,
    },
}));

const MainButton = ({onClick, startIcon, label}) => {
    const {isScreenMobile} = useResize()

    return (
        <>
            {isScreenMobile ?
                <StyledIconButton
                    aria-label={label}
                    onClick={onClick}
                >
                    {startIcon}
                </StyledIconButton>
                :
                <StyledButton
                    onClick={onClick}
                    startIcon={startIcon}
                >
                    {label}
                </StyledButton>
            }
        </>
    );
};

export default MainButton;