import * as colors from "@mui/material/colors";

export const darkTheme = {
    palette: {
        primary: {
            main: colors.grey[500]
        },
        primaryBgColor: colors.grey[500], // основной цвет
        primaryHvBgColor: colors.grey[400], // ховер цвет
        primaryTextColor: colors.common.black, // цвет текста
        secondaryTextColor: '#fff',
    },
    components: {
        signIn: {
            cardAppBarBackgroundColor: colors.indigo[500],
            cardAppBarColor: "#fff",
            appBarBackgroundColor: colors.grey[500],
            cardBackgroundColor: '#ddd9d9',
        },
        toolButton: {
            backgroundHoverColor: '#e8eaf6',
            backgroundColor: '#00000066',
            hoverColor: colors.common.black,
            color: colors.common.white,
        },
        table: {
            backgroundColor: '#909090',
            color: colors.common.white,
        },
        container: {
            backgroundColor: colors.common.black,
        },
        switch: {
            color: colors.yellow[500]
        }
    }
}