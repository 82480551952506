import React from 'react';
import {Routes, Route} from 'react-router-dom';
import MasterView from './containers/MasterView'
import RequestsView from './containers/RequestsView'
import SignInView from './containers/SignInView'
import NotFound from './components/NotFound'
import {RequireAuthentication} from './containers/Auth'
import './App.css';


function App() {
    return (
        <Routes>
            <Route path="/" element={<MasterView/>}>
                <Route index element={
                    <RequireAuthentication>
                        <RequestsView/>
                    </RequireAuthentication>}
                />
                <Route path="/sign-in" element={<SignInView/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    );
}

export default App;
