import React, {useState} from 'react'
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from "react-hook-form";
import {styled} from "@mui/material/styles";
import {useClasses} from "hooks/useClasses";
import {signIn} from '../../store/userSlice';
import * as colors from '@mui/material/colors';

import {Visibility, VisibilityOff} from '@mui/icons-material';
import {Box, Button, Card, CardContent, CardMedia, IconButton, TextField, Typography} from '@mui/material';

const defaultValues = {
    username: "",
    password: "",
};

const styles = (theme) => ({
    cardContainer: {
        width: '100%',
        height: 'calc(100% - 64px)',
        minHeight: 456,
        minWidth: 320,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.components.signIn.appBarBackgroundColor,
    },
    card: {
        maxWidth: 400,
        minWidth: 320,
        margin: '0 6px',
        overflow: 'hidden',
        backgroundColor: theme.components.signIn.cardBackgroundColor,
    },
    cardMedia: {
        height: 120,
        width: 400,
        backgroundColor: theme.components.signIn.cardAppBarBackgroundColor,
        color: theme.components.signIn.cardAppBarColor,
        boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
    },

    cardTitle: {
        padding: '32px 32px 16px',
        fontSize: 20,
        lineHeight: '24px'
    },
    errorMessage: {
        height: 19,
        padding: '24px 32px 0',
        display: 'block',
        textAlign: 'center',
        color: colors.red[500]
    },
    loginForm: {
        margin: 32
    },
    fieldContainer: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    fieldIcon: {
        color: colors.grey[600],
        padding: '12px 12px 10px 0'
    },

    textField: {
        width: '100%'
    },
    button: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
    },
    section: {paddingTop: 8},
    helperText: {
        fontSize: '0.75rem',
        margin: 0,
        color: colors.red[500]
    }
})

export const SubmitButton = styled(Button)(({theme}) => ({
    color: colors.common.black,
    backgroundColor: colors.blueGrey[50],
    '&:hover': {
        backgroundColor: '#00000066',
    },
}));

function SignInView() {
    const {handleSubmit, control, formState: {errors}} = useForm({defaultValues});
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {classes} = useClasses(styles)

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const handleSignInButtonClick = (data) => {
        dispatch(signIn(data))
            .then((authData) => {
                if (authData?.payload?.status === 400) {
                    setError('Неверный логин или пароль');
                } else {
                    navigate('/')
                }
            });
    }
    const handleFocus = () => error && setError(null)

    return (
        <div className={classes.cardContainer}>
            <Card className={classes.card}>
                <CardMedia className={classes.cardMedia}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            ОАО «ПУЛ транс»
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Автоматизированная система заявок на выполнение маневровой работы в порту «Усть-Луга»
                        </Typography>
                    </CardContent>
                </CardMedia>

                <div className={classes.loginForm}>
                    <Typography variant="h6" gutterBottom>Вход в систему</Typography>

                    <form className="form">
                        <section className={classes.section}>
                            <Controller
                                render={({field}) =>
                                    <TextField {...field}
                                               fullWidth
                                               label="Логин"
                                               variant="standard"
                                               onFocus={handleFocus}
                                    />}
                                name="username"
                                control={control}
                                rules={{required: 'Вы не указали логин'}}
                            />
                            <div className={classes.helperText}>
                                {errors?.username && <span>{errors?.username.message}</span>}
                            </div>
                        </section>

                        <section className={classes.section}>
                            <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                                <Controller
                                    render={({field}) =>
                                        <TextField {...field}
                                                   fullWidth
                                                   label='Пароль' variant="standard"
                                                   type={showPassword ? 'text' : 'password'}
                                                   onFocus={handleFocus}
                                        />}
                                    name="password"
                                    control={control}
                                    rules={{required: 'Вы не указали пароль'}}
                                />

                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </Box>
                            <div className={classes.helperText}>
                                {errors?.password && <span>{errors?.password.message}</span>}
                            </div>
                        </section>

                        <section className={classes.errorMessage}>
                            {error}
                        </section>

                        <section style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 20}}>
                            <SubmitButton
                                onClick={handleSubmit(handleSignInButtonClick)}
                                className={classes.button}
                            > Войти </SubmitButton>
                        </section>

                    </form>
                </div>
            </Card>
        </div>
    )

}

export default SignInView
