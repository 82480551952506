import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";
import {useClasses} from "hooks/useClasses";
import {signOut} from "store/userSlice";
import {clearError} from "store/requestSlice";
// import ThemeSwitch from "components/ThemeSwitch";
import MainBar from "components/MainBar";
import {lightTheme} from "themes/lightTheme";
import {darkTheme} from "themes/darkTheme";
import {LOCAL_STORAGE_THEME} from "constants/constants";


const styles = (theme) => {
    return ({
        masterContainer: {
            width: '100%',
            height: '100vh',
        }
    })
}

function MasterView() {
    const requests = useSelector(state => state.requests)
    const user = useSelector(state => state.user)
    const {classes} = useClasses(styles)
    const dispatch = useDispatch()
    const [mode, setMode] = useState(null)
    const [render, setRender] = useState(false)
    const [currentTheme, setCurrentTheme] = useState(null)

    useEffect(() => {
        const theme = localStorage.getItem(LOCAL_STORAGE_THEME)
        if (!theme) {
            setMode('light')
            localStorage.setItem(LOCAL_STORAGE_THEME, 'light')
        } else setMode(theme)

        setRender(true)
    }, [])


    useEffect(() => {
        if (render) {
            if (mode === 'dark') {
                setCurrentTheme(createTheme(darkTheme))
            } else {
                setCurrentTheme(createTheme(lightTheme))
            }
        }
    }, [render, mode])

    useEffect(() => {
        if (requests.error) {
            dispatch(clearError())
            dispatch(signOut(requests.error))
        }
    }, [requests, dispatch])


    // const handleClick = () => {
    //     if (mode === 'light') {
    //         setMode('dark')
    //         localStorage.setItem(LOCAL_STORAGE_THEME, 'dark')
    //     } else {
    //         setMode('light')
    //         localStorage.setItem(LOCAL_STORAGE_THEME, 'light')
    //     }
    // }


    if (!currentTheme) {
        return null
    }
    return (
        <ThemeProvider theme={currentTheme}>
            <div className={classes.masterContainer}>
                <MainBar user={user}/>
                <Outlet/>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={requests.snackbarShow}
                    message={requests.snackbarMessage}
                />
                {/*<ThemeSwitch onClick={handleClick} theme={mode}/>*/}
            </div>
        </ThemeProvider>
    )
}


export default (MasterView)
