import { configureStore } from "@reduxjs/toolkit";
import user from "./userSlice";
import requests from "./requestSlice";

export default configureStore({
    reducer: {
        user,
        requests,
    },
});
