const devConfig = {
    apiServerUrl: 'http://localhost:52445',
    apiBaseUrl: `http://localhost:52445/pultrans/driversWorkspace`
}

const prodConfig = {
    apiServerUrl: 'https://api.claris.su/main',
    apiBaseUrl: `https://api.claris.su/main/pultrans/driversWorkspace`
}

let config;

if (process.env.NODE_ENV === 'development') {
    config = devConfig;
} else if (process.env.NODE_ENV === 'production') {
    config = prodConfig;
}
export default config;



