// Идентификатор статуса 'На исполнении'
// const ON_EXECUTION_STATUS_ID = '2100080451000'

// Идентификатор отделения Lugaport
const LUGAPORT_DEPARTMENT_ID = '4714035218000'
// Идентификатор отделения ОАО «РЖД»
const RZD_DEPARTMENT_ID = '4115484377000'
// Идентификатор отделения ОАО «Усть-Луга Ойл»
const UST_LUGA_OIL_DEPARTMENT_ID = '2099938159000'
// Идентификатор отделения АО «Сибур-Транс»
const SIBUR_TRANS_DEPARTMENT_ID = '2105268795000'
// Идентификатор отделения ОАО «МТП Усть-Луга»
const MTP_UST_LUGA_DEPARTMENT_ID = '2265899423000'
// Идентификатор отделения ОАО «ЕСТ»
const EST_DEPARTMENT_ID = '2337491395000'
// Идентификатор отделения ООО «УПК»
const UPK_DEPARTMENT_ID = '2497533129000'
// Идентификатор отделения ОАО «УЛКТ»
const ULTK_DEPARTMENT_ID = '2762696083000'
// Идентификатор отделения АЖПК
const AJPK_DEPARTMENT_ID = '2808380360000'
// Идентификатор отделения ОАО «ЛТФ»
const LTF_DEPARTMENT_ID = '2959737708000'
// Идентификатор вида работ 'Уборка'
const REMOVE_REQUEST_TYPE_ID = '2099938140000'
// Идентификатор вида работ 'Подача'
const SUPPLY_REQUEST_TYPE_ID = '2099938139000'
// Идентификатор вида работ 'ПодачаПаркЮжный'
const SUPPLY1_REQUEST_TYPE_ID = '3759566139000'
// Идентификатор вида работ 'ПодачаПаркНефтяной'
const SUPPLY2_REQUEST_TYPE_ID = '3759566142000'
// Идентификатор вида работ 'ПодачаСортировочнаяСистема'
const SUPPLY3_REQUEST_TYPE_ID = '3759566143000'
// Идентификатор вида работ 'ПодачаЛужская'
const SUPPLY4_REQUEST_TYPE_ID = '3759566144000'
// Идентификатор вида работ 'Маневровые работы'
const SHUNTING_WORK_REQUEST_TYPE_ID = '2099938136000'
// Идентификатор вида работ 'Маневровая Работа С Одиночными Вагонами'
// const SINGLE_CAR_SHUNTING_WORK_REQUEST_TYPE_ID = '2768786880000'
// Идентификатор вида работ 'Уборка Без Формирования'
const REMOVE_WO_FORMING_REQUEST_TYPE_ID = '2768786879000'
// Идентификатор вида работ 'Уборка С Формированием'
const REMOVE_W_FORMING_REQUEST_TYPE_ID = '2768786877000'
// Идентификатор вида работ 'Подача Без Формирования'
const SUPPLY_WO_FORMING_REQUEST_TYPE_ID = '2768786876000'
// Идентификатор вида работ 'Подача С Формированием'
const SUPPLY_W_FORMING_REQUEST_TYPE_ID = '2768786874000'
// Идентификатор вида работ 'Погрузка'
// const LOAD_REQUEST_TYPE_ID = '2808380189000'
// Идентификатор вида работ 'Выгрузка'
// const UNLOAD_REQUEST_TYPE_ID = '2808380190000'
// Идентификатор вида работ 'ТехническоеОбслуживание'
// const SERVICE_REQUEST_TYPE_ID = '3370697737000'
// Идентификатор вида работ 'Подача Из Сортировочной Системы'
const SUPPLY_SORTSYSTEM_FORMING_REQUEST_TYPE_ID = '3994286876000'
// Идентификатор вида работ 'МежпарковаяПередача'
// const MEZPARKOV_PEREDACHA_REQUEST_TYPE_ID = '4428036771000'
// Идентификатор вида работ 'ПерестановкаВагонов'
// const PERESTANOVKA_VAGONOV_REQUEST_TYPE_ID = '4428036807000'

export const calculateStateProps = (selected) => {

    const defaultState = {
        arrivalUnderTrainButtonShow: false,
        supplyStartButtonShow: false,
        removeFinishButtonShow: false,
        removeFinishWorkButtonShow: false,
        trainFixingButtonShow: false,
        workStartButtonShow: false
    };

    if (!selected || !selected.departmentId)
        return defaultState;

    switch (selected.departmentId) {
        case LUGAPORT_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID || selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                arrivalUnderTrainButtonEnable: !selected.arrivalUnderTrainDate,
                removeFinishWorkButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID || selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                removeFinishWorkButtonEnable: !selected.actualEndDate
            }

        case RZD_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: selected.requestType.indexOf('(в хвосте)') === -1,
                arrivalUnderTrainButtonEnable: !selected.arrivalUnderTrainDate
            }

        case UST_LUGA_OIL_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID,
                arrivalUnderTrainButtonEnable: !selected.arrivalUnderTrainDate,
                supplyStartButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID,
                supplyStartButtonEnable: !selected.actualStartDate,
                removeFinishButtonShow: selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                removeFinishButtonEnable: !selected.actualEndDate,
                trainFixingButtonShow: false,
                workStartButtonShow: selected.requestTypeId === SHUNTING_WORK_REQUEST_TYPE_ID,
                workStartButtonEnable: !selected.actualStartDate
            }

        case SIBUR_TRANS_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID,
                arrivalUnderTrainButtonEnable: !selected.arrivalUnderTrainDate,
                supplyStartButtonShow: false,
                removeFinishButtonShow: false,
                trainFixingButtonShow: selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                trainFixingButtonEnable: !selected.trainFixingDate,
                workStartButtonShow: false
            }

        case MTP_UST_LUGA_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: false,
                supplyStartButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID,
                supplyStartButtonEnable: !selected.actualStartDate,
                removeFinishButtonShow: false,
                trainFixingButtonShow: selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                trainFixingButtonEnable: !selected.trainFixingDate,
                workStartButtonShow: false
            }

        case EST_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: false,
                supplyStartButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID ||
                    ((selected.requestTypeId === SUPPLY1_REQUEST_TYPE_ID
                        || selected.requestTypeId === SUPPLY2_REQUEST_TYPE_ID
                        || selected.requestTypeId === SUPPLY3_REQUEST_TYPE_ID
                        || selected.requestTypeId === SUPPLY4_REQUEST_TYPE_ID) && selected.requestType.indexOf('подача на терминал') !== -1),
                supplyStartButtonEnable: !selected.actualStartDate,
                removeFinishButtonShow: false,
                trainFixingButtonShow: selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                trainFixingButtonEnable: !selected.trainFixingDate,
                workStartButtonShow: false
            }

        case UPK_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: false,
                supplyStartButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID,
                supplyStartButtonEnable: !selected.actualStartDate,
                removeFinishButtonShow: false,
                trainFixingButtonShow: selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                trainFixingButtonEnable: !selected.trainFixingDate,
                workStartButtonShow: false
            }

        case ULTK_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: false,
                supplyStartButtonShow: selected.requestTypeId === SUPPLY_WO_FORMING_REQUEST_TYPE_ID || selected.requestTypeId === SUPPLY_W_FORMING_REQUEST_TYPE_ID || selected.requestTypeId === SUPPLY_SORTSYSTEM_FORMING_REQUEST_TYPE_ID,
                supplyStartButtonEnable: !selected.actualStartDate,
                removeFinishButtonShow: false,
                trainFixingButtonShow: selected.requestTypeId === REMOVE_WO_FORMING_REQUEST_TYPE_ID || selected.requestTypeId === REMOVE_W_FORMING_REQUEST_TYPE_ID,
                trainFixingButtonEnable: !selected.trainFixingDate,
                workStartButtonShow: selected.requestTypeId === SUPPLY_WO_FORMING_REQUEST_TYPE_ID || selected.requestTypeId === SUPPLY_W_FORMING_REQUEST_TYPE_ID || selected.requestTypeId === SUPPLY_SORTSYSTEM_FORMING_REQUEST_TYPE_ID,
                workStartButtonEnable: !selected.actualStartDate
            }

        case AJPK_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: false,
                supplyStartButtonShow: selected.requestTypeId === SUPPLY_REQUEST_TYPE_ID,
                supplyStartButtonEnable: !selected.actualStartDate,
                removeFinishButtonShow: false,
                trainFixingButtonShow: selected.requestTypeId === REMOVE_REQUEST_TYPE_ID,
                trainFixingButtonEnable: !selected.trainFixingDate,
                workStartButtonShow: false
            }

        case LTF_DEPARTMENT_ID:
            return {
                arrivalUnderTrainButtonShow: false,
                supplyStartButtonShow: false,
                removeFinishButtonShow: false,
                trainFixingButtonShow: false,
                workStartButtonShow: false
            }

        default:
            return defaultState;
    }
}