import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import config from '../etc/config';
import {LOCAL_STORAGE_USER} from "../constants/constants";


export const signIn = createAsyncThunk(
    'user/signIn',
    async function (data, {rejectWithValue, dispatch}) {
        try {
            const {username, password} = data
            let userSignInResponse = await axios({
                method: 'POST',
                url: `${config.apiServerUrl}/Token`,
                headers: {
                    'Authorization': null,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: `grant_type=password&username=${username}&password=${password}`,
            })
            const user = {
                token: userSignInResponse.data.access_token,
                isAuthenticated: true
            }
            const requestConfig = {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Accept': 'application/json'
                },
                params: {
                    fields: 'Name,Locomotive.Id as LocomotiveId,Locomotive.Name as LocomotiveName,' +
                        'Department.Id as departmentId,Department.Name as DepartmentName'
                }
            }
            let userInfoResponse = await axios.get(`${config.apiBaseUrl}/v1/Employees/Current`, requestConfig)
            user.name = userInfoResponse.data.name
            user.locomotiveId = userInfoResponse.data?.locomotiveId
            user.locomotiveName = userInfoResponse.data?.locomotiveName
            user.departmentId = userInfoResponse.data?.departmentId
            user.departmentName = userInfoResponse.data?.departmentName

            return user;
        } catch (error) {
            return rejectWithValue({
                status: error.response?.status || 500,
                message: error.response?.data?.error_description || 'Server Error'
            });
        }
    }
);

const getInitialState = () => {
    const userData = localStorage.getItem(LOCAL_STORAGE_USER)
    const user = JSON.parse(userData)
    let initState;
    if (user?.isAuthenticated) {
        initState = {
            token: user.token,
            isAuthenticated: user.isAuthenticated,
            name: user.name,
            locomotiveId: user.locomotiveId,
            locomotiveName: user.locomotiveName,
            departmentId: user.departmentId,
            departmentName: user.departmentName,
        }
    } else initState = {
        token: null,
        isAuthenticated: false,
        name: null,
        locomotiveId: null,
        locomotiveName: null,
        departmentId: null,
        departmentName: null,
    }
    return initState
}


const userSlice = createSlice({
    name: "user",
    initialState: getInitialState(),
    reducers: {
        signInFromLocalStorage: (state, action) => {
            state.token = action.payload.token
            state.isAuthenticated = action.payload.isAuthenticated
            state.name = action.payload.name
            state.locomotiveId = action.payload.locomotiveId
            state.locomotiveName = action.payload.locomotiveName
            state.departmentId = action.payload.departmentId
            state.departmentName = action.payload.departmentName
        },

        signOut: (state, action) => {
            localStorage.removeItem(LOCAL_STORAGE_USER)
            state.isAuthenticated = false
        },
    },
    extraReducers: {

        [signIn.pending]: () => {
            localStorage.removeItem(LOCAL_STORAGE_USER)
        },
        [signIn.fulfilled]: (state, action) => {
            state.token = action.payload.token
            state.isAuthenticated = action.payload.isAuthenticated
            state.name = action.payload.name
            state.locomotiveId = action.payload.locomotiveId
            state.locomotiveName = action.payload.locomotiveName
            state.departmentId = action.payload.departmentId
            state.departmentName = action.payload.departmentName
            localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(action.payload))
        },
        [signIn.rejected]: (state, action) => {
            console.log(action.payload)
        },

    }
});

export const {signOut} = userSlice.actions;

export default userSlice.reducer;
