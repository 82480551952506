import * as colors from "@mui/material/colors";

export const lightTheme = {
    palette: {
        primary: {
            main:  colors.indigo[500]
        },
        primaryBgColor: colors.indigo[500], // основной цвет
        primaryHvBgColor: colors.indigo[400], // ховер цвет
        primaryTextColor: colors.common.white, // цвет текста
        secondaryTextColor: '#5b5a5a',
    },
    components: {
        signIn: {
            cardAppBarBackgroundColor: colors.indigo[500],
            cardAppBarColor: colors.common.white,
            appBarBackgroundColor: colors.common.white,
            cardBackgroundColor: colors.common.white,
        },
        toolButton: {
            backgroundColor: '#e8eaf6',
            backgroundHoverColor: '#00000066',
            color: colors.common.black,
            hoverColor: colors.common.white,
        },
        table: {
            backgroundColor: colors.common.white,
            color: colors.common.black,
        },
        container: {
            backgroundColor: colors.common.white,
        },
        switch: {
            color: colors.grey[500]
        }
    }
}